<template>
  <MainLayout>
		<div class="w-full">
			<div class="flex items-center w-full mb-6 title my-11">
				<span class="text-2xl font-bold cursor-pointer icon-arrow-left normal text-gray"
          @click="changeIsOpen(true)"></span>
        <ModalConfirm 
          title="Batalkan Add Vendor Candidate?" 
          message="Semua informasi akan dihapus, apakah Anda yakin?" 
          icon="rejected" textButton="Yes" 
          :isOpen="isCancelAddVendorCandidate" 
          @changeIsOpen="changeIsOpen($event)" 
          @click="closeBackToProjectDetail"
          colorButton="error"></ModalConfirm>
				<h4 class="pl-3 text-3xl font-semibold text-gray-darkest">#{{proposalInformation.projectId}} - Add Vendor Candidate</h4>
			</div>
			<div class="w-full mb-10 bg-white dashboard rounded-2xl shadow-gray-sm">
				<ValidationObserver ref="formAddVendor">
					<div v-for="(n,i) in categories" :key="i" class="w-full p-6 border-b cursor-not-allowed border-gray-light">
            <div  class="flex justify-between">
              <div class="flex">
                <span class="mr-6 text-2xl icon-list text-gray-light"></span>
                <h4 class="text-base font-semibold text-gray-light">{{n}}</h4>
              </div>
              <div>
                <p 
                  class="text-2xl icon-chevron-down text-gray-light"></p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <VendorCandidate :totalLocalPartner="totalLocalPartner" :totalVendorCandidate="totalVendorCandidate" :add="true" @collapse="changeCollapse($event, 'vendorCandidate')" />
          </div>
          <div class="w-full p-6 border-b cursor-not-allowed border-gray-light">
            <div class="flex justify-between">
              <div class="flex">
                <span class="mr-6 text-2xl icon-check-circle text-gray-light"></span>
                <h4 class="text-base font-semibold text-gray-light">SCORING INFORMATION</h4>
              </div>
              <div>
                <p 
                  class="text-2xl icon-chevron-down text-gray-light"></p>
              </div>
            </div>
          </div>
          

					
					<div class="flex justify-center w-full p-6 sm:justify-end">
						<ButtonGista
              type="primary"
              @click="submit"
              customClass="w-full sm:w-60"
              >Save Changes</ButtonGista>
					</div>
				</ValidationObserver>
			</div>
		</div>
	</MainLayout>
</template>
<script>
	import { MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
  import { PATH_ROOT, PROJECT_DETAIL } from '@/core/constant/routeName'
  import { MESSAGE_SUCCESS_ADD_VENDOR_CANDIDATE } from '@/core/constant/successMessage'  
  import VendorCandidate from './createNewProject/VendorCandidate.vue'
  import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'AddVendorCandidate',
    data() {
      return {
        isCancelAddVendorCandidate: false,
        categories: ['PROPOSAL INFORMATION', 'PROJECT INFORMATION', 'PROJECT REQUIREMENT'],
        totalVendorCandidate: 4,
        totalLocalPartner: [],
      }
    },
    computed: {
      proposalInformation() {
        return this.$store.state.rfp.proposalInformation;
      },
      vendor() {
        return this.$store.state.rfi.vendor
      },
      vendorList() {
        return this.$store.state.rfp.vendorList
      },
      vendorCandidate: {
        get() {
          return this.$store.state.rfp.vendorCandidate
        },
        set(value) {
          this.$store.commit('rfp/SET_VENDOR_CANDIDATE', value)
        }
      },
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      },
      collapse: {
        get() {
          return this.$store.state.rfp.collapse
        },
        set(value) {
          this.$store.commit('rfp/SET_COLLAPSE', value)
        }
      },
    },
    methods: {
      toggleCancelAddVendorCandidate() {
        this.isCancelAddVendorCandidate = !this.isCancelAddVendorCandidate
      },
      closeBackToProjectDetail() {
        this.isCancelAddVendorCandidate = false
        this.$store.dispatch('rfp/resetState')
        setTimeout(() => {
          this.$router.push(`/${PATH_ROOT}/${PROJECT_DETAIL}/${this.$route.params.projectId}`).catch(() => ({}))
        }, 200)
      },
      changeIsOpen(val) {
        this.isCancelAddVendorCandidate = val
      },
      addVendorCandidate() {
        this.vendorCandidate = [...this.vendorCandidate, {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          isNewVendor: true,
          reason: '',
          isActive: true,
          isApprove: true,
          isPerluPoc: true,
          isDeleted: false,
          disqualifiedReason: '',
          disabled: false,
          isLoading: false,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: [],
        }]
        this.totalLocalPartner = [ ...this.totalLocalPartner, 0 ]
      },
      addLocalPartner(i) {
        const localPartnerNew = {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          reason: '',
          isLoading: false,
          isActive: true,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
        }
        this.vendorCandidate[i].localPartners = [
          ...this.vendorCandidate[i].localPartners, 
          localPartnerNew
        ]
      },
      changeCollapse(e, key) {
        this.collapse[key] = !this.collapse[key]
      },
      async submit() {
        try {
          //show loading
          this.modal.modalLoading = true
          const success = await this.$refs.formAddVendor.validate()
          //custom error validate vendor candidate
			    const errorVendorCandidate = await this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
          const resErrorVendorCandidateField = await this.$store.dispatch('errorRfp/checkErrorVendorCandidateField')
          if (!success || !errorVendorCandidate || !resErrorVendorCandidateField) {
            //loading out
            this.modal.modalLoading = false
            //show modal error validate add vendor candidate
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
            return
          }
          const response = await this.$store.dispatch('rfp/putProjectVendorCandidate')
          if (response?.status < 300 && response?.status >= 200) {
            this.$router.push(`/${PATH_ROOT}/${PROJECT_DETAIL}/${this.$route.params.projectId}`).catch(() => ({}))
            this.$store.dispatch('rfp/resetState')
            //loading out
            this.modal.modalLoading = false
            //show modal success add vendor candidate
            this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_ADD_VENDOR_CANDIDATE }
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
          
        } catch (error) {
          console.log(error);
          //laoding out when error
          this.modal.modalLoading = false
          //show modal error add vendor candidate
          this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      },
    },
    async mounted() {
			this.$store.dispatch('rfp/getProposalInformation', this.$route.params.projectId)
			const responseVendorCandidate = await this.$store.dispatch('rfp/getProjectVendorCandidate', this.$route.params.projectId)
      this.totalVendorCandidate = responseVendorCandidate.data.result?.length
      this.totalLocalPartner = responseVendorCandidate.data.result?.map(e => (e.localPartners.length))
      this.addVendorCandidate()
    },
    destroyed() {
      this.$store.dispatch('rfp/resetState')
      this.$store.dispatch('errorRfp/resetState', { saveAsDraft: false })
    },
    components: {
      VendorCandidate,
      ModalConfirm
    }
  }
</script>